<template>
  <div class="form">
    <el-form :inline="true" :model="search">
      <el-form-item>
        <el-input v-model="search.name" class="mobile" clearable>
          <template slot="prepend">姓名</template>
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-input v-model="search.mobile" class="mobile" clearable>
          <template slot="prepend">手机号</template>
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="$emit('search', search)">搜索</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      search: { mobile: '',
      name: '' }
    };
  }
};
</script>

<style lang="scss" scoped>
.form {
  text-align: left;
  .account.el-input {
    width: 160px;
  }
  .mobile.el-input {
    width: 220px;
  }
  .el-select {
    width: 100px;
  }
  .add-btn {
    background-color: #1ecab8;
    border: 1px solid #1ecab8;
    color: #fff;
  }
}
@media screen and (max-width: 480px) {
 .el-form--inline .el-form-item{
   display: block;
 }
}
</style>