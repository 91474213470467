import API from "@/api";

export default {
  table: params => {
    return API.requestGet({ 
      url: "loan/repayment/records",
       params 
      });
  },
  setPaid: params => {
    return API.requestPost({ 
      url: "loan/repayment/auditRecord",
      data: params 
    });
  },
};
